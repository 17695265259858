var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-database-import-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Cargas de BDGD para GeoPerdas ")])]},proxy:true}])},[(_vm.accessReleased('CARREGAR_BDGD_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-carga","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova Carga ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"cargas-bdgds-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),(_vm.cargasAguardando)?_c('v-alert',{staticClass:"mb-5 py-1 px-1",attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Caso queira cancelar as cargas, "),_c('a',{staticClass:"link",on:{"click":_vm.openDialogDeleteAll}},[_vm._v("clique aqui")]),_vm._v(" para remover todas que estão aguardando processamento. ")]):_vm._e(),_c('v-data-table',{staticClass:"cargas-bdgds-table",attrs:{"headers":[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Mês dos dados',
          value: 'data_registro'
        },
        {
          text: 'Entidades',
          value: 'entidades'
        },
        {
          text: 'Nº de linhas',
          value: 'numero_linhas'
        },
        {
          text: 'Servidor',
          value: 'servidor'
        },
        {
          text: 'Database',
          value: 'database'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma carga de BDGD encontrada"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.entidades",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"clickable-item",attrs:{"id":"cargas-bdgds-column-entity"},on:{"click":function($event){return _vm.openDialogTabelas(item.entidades)}}},[_vm._v(" "+_vm._s(_vm._f("handleTabelasColumnValue")(item.entidades))+" ")])]}},{key:"item.servidor",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"title":("Host: " + (item.host) + " Port: " + (item.port))}},[_vm._v(" "+_vm._s(item.servidor)+" ")])]}},{key:"item.numero_linhas",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"id":"cargas-bdgds-numero-linhas"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas))+" ")])]}},{key:"item.data_registro",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:!['SUCESSO', 'FALHA'].includes(item.status) ? 'cursor-block' : '',attrs:{"min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.loadingLogsTecnicos[item.id],"disabled":!['SUCESSO', 'FALHA'].includes(item.status)},on:{"click":function () { return _vm.baixarLogsTecnicos(item.id); }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more-conciliacao","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.canDeleteItem(item))?_c('v-list-item',{on:{"click":function($event){return _vm.openDialogDelete(item)}}},[_c('v-list-item-icon',{staticClass:"mx-0 my-0 py-4"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-trash-can-outline")])],1),_c('v-list-item-title',[_vm._v(" Deletar ")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.openDialogCreationLog(item)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-table-search ")]),_vm._v(" Log ")],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as cargas de BDGD")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){return _vm.deletarItem(_vm.dialogDeleteData.id)}}},[_c('template',{slot:"title"},[_vm._v("Deletar Carga #"+_vm._s(_vm.dialogDeleteData.id))]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar a carga de BDGD "),_c('strong',[_vm._v("#"+_vm._s(_vm.dialogDeleteData.id))]),_vm._v("? ")])],2),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialogTabelas),callback:function ($$v) {_vm.dialogTabelas=$$v},expression:"dialogTabelas"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-3",staticStyle:{"text-align":"left !important"}},[_c('h2',[_vm._v("Entidades")])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.getTabelasConcat)+" ")]),_c('v-divider'),_c('v-card-actions',{staticStyle:{"text-align":"right !important","display":"block !important"}},[_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogTabelas = false}}},[_vm._v(" OK ")])],1)],1)],1),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDeleteAll},on:{"update:dialog":function($event){_vm.dialogDeleteAll=$event},"submitTriggered":function($event){return _vm.deletarAllItens()}}},[_c('template',{slot:"title"},[_vm._v("Deletar Cargas")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja deletar todas as cargas de entidades aguardando processamento? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }