<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-database-import-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Cargas de BDGD para GeoPerdas
        </div>
      </template>

      <v-btn
        v-if="accessReleased('CARREGAR_BDGD_ADICIONAR')"
        id="btn-nova-carga"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Nova Carga
      </v-btn>

      <v-text-field
        id="cargas-bdgds-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <v-divider class="mt-10" />

      <v-alert
        dense
        outlined
        type="error"
        class="mb-5 py-1 px-1"
        v-if="cargasAguardando"
      >
        Caso queira cancelar as cargas,
        <a
          class="link"
          @click="openDialogDeleteAll"
          >clique aqui</a
        >
        para remover todas que estão aguardando processamento.
      </v-alert>

      <v-data-table
        class="cargas-bdgds-table"
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'Mês dos dados',
            value: 'data_registro'
          },
          {
            text: 'Entidades',
            value: 'entidades'
          },
          {
            text: 'Nº de linhas',
            value: 'numero_linhas'
          },
          {
            text: 'Servidor',
            value: 'servidor'
          },
          {
            text: 'Database',
            value: 'database'
          },
          {
            text: 'Status',
            value: 'status'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma carga de BDGD encontrada"
      >
        <template v-slot:[`item.entidades`]="{ item }">
          <div
            id="cargas-bdgds-column-entity"
            class="clickable-item"
            @click="openDialogTabelas(item.entidades)"
          >
            {{ item.entidades | handleTabelasColumnValue }}
          </div>
        </template>
        <template v-slot:[`item.servidor`]="{ item }">
          <div :title="`Host: ${item.host} Port: ${item.port}`">
            {{ item.servidor }}
          </div>
        </template>
        <template v-slot:[`item.numero_linhas`]="{ item }">
          <div id="cargas-bdgds-numero-linhas">
            {{ item.numero_linhas | parseNumberToIntegerBR }}
          </div>
        </template>
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }} v{{ item.versao }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="
              !['SUCESSO', 'FALHA'].includes(item.status) ? 'cursor-block' : ''
            "
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="() => baixarLogsTecnicos(item.id)"
            :loading="loadingLogsTecnicos[item.id]"
            :disabled="!['SUCESSO', 'FALHA'].includes(item.status)"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more-conciliacao"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="canDeleteItem(item)"
                @click="openDialogDelete(item)"
              >
                <v-list-item-icon class="mx-0 my-0 py-4">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title> Deletar </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCreationLog(item)">
                <v-list-item-title>
                  <v-icon small> mdi-table-search </v-icon>
                  Log
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar as cargas de BDGD</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="deletarItem(dialogDeleteData.id)"
    >
      <template slot="title">Deletar Carga #{{ dialogDeleteData.id }}</template>
      <template slot="body">
        Tem certeza que deseja deletar a carga de BDGD
        <strong>#{{ dialogDeleteData.id }}</strong
        >?
      </template>
    </dialog-delete>
    <v-dialog
      v-model="dialogTabelas"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Entidades</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          {{ getTabelasConcat }}
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogTabelas = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialog-delete
      :dialog.sync="dialogDeleteAll"
      @submitTriggered="deletarAllItens()"
    >
      <template slot="title">Deletar Cargas</template>
      <template slot="body">
        Tem certeza que deseja deletar todas as cargas de entidades aguardando
        processamento?
      </template>
    </dialog-delete>
  </v-container>
</template>

<script>
import CargasBdgdsService from '@/services/CargasBdgdsService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],
  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    DialogDelete: () => import('@/components/general/DialogDelete'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    powerupService: CargasBdgdsService,
    powerupEntityName: 'Cargas de BDGD',
    dialogTabelas: false,
    dialogTabelasData: [],
    dialogCreationLog: false,
    dialogCreationLogData: {
      username: null,
      created: null
    },
    dialogDeleteAll: false,
    dialogDelete: false,
    dialogDeleteData: {
      id: null,
      tuc: null
    },
    search: '',
    loadingLogsTecnicos: {}
  }),
  filters: {
    handleTabelasColumnValue(arrayTabelas) {
      let formattedValue = arrayTabelas[0].toUpperCase();
      let lengthArray = arrayTabelas.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  },
  methods: {
    openDialogTabelas(arrNameTables) {
      this.dialogTabelasData = arrNameTables;
      this.dialogTabelas = true;
    },
    openDialogCreationLog(item) {
      this.dialogCreationLog = true;
      this.dialogCreationLogData = {
        username: item.usuario,
        created: item.created
      };
    },
    canDeleteItem(item) {
      return item.status === 'AGUARDANDO';
    },
    openDialogDelete(item) {
      this.dialogDelete = true;
      this.dialogDeleteData = {
        id: item.id
      };
    },
    deletarItem(id) {
      CargasBdgdsService.deletar(id)
        .then(() => {
          this.$toast.success('Carga de BDGD removida com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar a carga de BDGD.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDelete = false;
    },
    openDialogDeleteAll() {
      this.dialogDeleteAll = true;
    },
    deletarAllItens() {
      const companyId = this.userLoggedCompany.id;
      CargasBdgdsService.deletarPorStatus(companyId, 'AGUARDANDO')
        .then(() => {
          this.$toast.success('Cargas removidas com sucesso.', '', {
            position: 'topRight'
          });
          this.$emit('rechargeTable');
        })
        .catch((error) => {
          this.$toast.error('Erro ao deletar as cargas.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogDeleteAll = false;
    },
    baixarLogsTecnicos(cargaId) {
      this.loadingLogsTecnicos[cargaId] = true;
      CargasBdgdsService.baixarLogsTecnicos(cargaId)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `logs-tecnicos-carga-entidades-prog-geo-perdas-${cargaId}.log`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error('Falha no download dos logs técnicos.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loadingLogsTecnicos[cargaId] = false));
    }
  },
  computed: {
    getTabelasConcat() {
      return this.dialogTabelasData.join(', ').toUpperCase();
    },
    cargasAguardando() {
      return this.items.filter((item) => item.status === 'AGUARDANDO').length;
    },
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    }
  }
};
</script>

<style scoped>
.link {
  text-decoration: underline;
}
</style>
